div.product-page-venue {
  position: relative;
  width: 100%;
  margin-top: 16px;
}

p.product-subtitle {
  color: #110000;
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
}
