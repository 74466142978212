div.discount-spacer {
  width: 100%;
  background-color: transparent;
  margin-bottom: 20px;
}

div.discount-container {
  margin: 16px 0px 0px 0px;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
}

p.discount-container-title {
  margin-bottom: 0px;
  color: #11100d;
  font-size: 20px;
  font-weight: 600;
}

p.discount-no-content {
  padding: 10px 0px;
  text-align: center;
}

div.discount-container-inline {
  display: flex;
  flex-wrap: wrap;
}
