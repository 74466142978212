p.terminal-details-venue-title {
  padding: 20px 0px 0px 0px;
  font-weight: bold;
}

button.terminal-details-sumup-btn {
  background-color: #000;
  color: #fff;
}

button.terminal-details-sumup-btn:hover {
  background-color: #111;
}

button.terminal-details-sumup-btn:active {
  background-color: #222;
}

img.terminal-details-sumup-btn-icon {
  width: 32px;
  height: 32px;
}