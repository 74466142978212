div.top-bars-card-container {
  position: relative;
  margin-top: 10px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 16px;
}

p.top-bars-card-title {
  display: inline-block;
  height: 30px;
  color: #110000;
  font-size: 20px;
  font-weight: 600;
}

table.top-bars-table {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

table.top-bars-table th {
  padding: 10px 0px;
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}

table.top-bars-table tr {
  border-bottom: 1px solid #e3e6ee;
}

table.top-bars-table tr:first-child td:first-child {
  color: #ffc800;
}

table.top-bars-table tr:nth-child(2) td:first-child {
  color: #aeb8ce;
}

table.top-bars-table tr:nth-child(3) td:first-child {
  color: #d6652f;
}

table.top-bars-table tr:last-child {
  border: none !important;
}

table.top-bars-table td {
  padding: 16px 0px;
  color: #11100d;
  font-size: 13px;
  font-weight: 600;
}

table.top-bars-table td:first-child {
  width: 80px;
  padding-right: 20px;
  text-align: right;
}

div.top-bars-card-dropdown {
  position: absolute;
  top: 10px;
  right: 40px;
}
