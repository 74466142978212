.uploader-container {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 20px 0px;
  border-radius: 14px;
  border: 2px solid hsl(0, 0%, 82%);
  text-align: center;
  background-color: #fff;
}

.uploader-container.active {
  border-style: dashed;
  background-color: rgba(255, 200, 0, 0.05);
}

.uploader-label {
  color: #9b9b9b;
  font-family: 'Helvetica Neue';
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.uploader-file {
  display: none;
}

.uploader-splitter {
  position: relative;
}

.uploader-splitter hr {
  width: 170px;
  border: 1px solid #d2d2d2;
}

.uploader-splitter p {
  position: absolute;
  top: -10px;
  left: calc(50% - 13px);
  padding: 0px 5px;
  color: #9ca3ab;
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  background-color: #fff;
}

.uploader-splitter p.active {
  background-color: rgba(255, 200, 0, 0.05);
}

.uploader-remove-btn {
  margin-left: 5px;
  cursor: pointer;
}
