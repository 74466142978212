div.details-venue-spacer {
  width: 100%;
  margin: 10px 0px;
}

div.details-venue-gallery-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 10px 0px;
  padding: 5px 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 200px;
  border-radius: 14px;
  border: 7px solid transparent;
  text-align: center;
  background-color: #fff;
  zoom: 1;
}

div.details-venue-gallery-area {
  position: relative;
  align-self: center;
  margin: 0px 5px;
  width: auto;
  height: 100%;
}

div.details-venue-gallery-container img {
  width: auto;
  height: 100%;
}

p.details-venue-gallery-title {
  padding: 20px 0px 10px 0px;
  font-weight: bold;
}

div.details-venue-gallery-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

div.details-venue-gallery-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

div.details-venue-spacer {
  margin: 16px 0px;
}
