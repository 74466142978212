div.gateway-spacer {
  width: 100%;
  background-color: transparent;
  margin-bottom: 20px;
}

p.gateway-container-title {
  margin-bottom: 0px;
  color: #11100d;
  font-size: 20px;
  font-weight: 600;
}

div.gateway-page {
  position: relative;
  width: 100%;
  margin-top: 16px;
}
