div.sumup-card-container {
  position: relative;
  margin-top: 10px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 16px;
}

p.sumup-card-title {
  margin: 0px;
  padding: 0px;
  color: #110000;
  font-size: 20px;
  font-weight: 600;
}

p.sumup-card-title-alt {
  margin: 0px;
  padding: 0px;
  color: #444;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
}

div.sumup-card-table-container {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}

p.sumup-card-table-title {
  padding: 0px;
  color: #222;
  font-size: 16px;
  font-weight: 600;
}

p.sumup-card-table-text {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
}
