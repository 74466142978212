div.top-products-container {
  position: relative;
  margin-top: 10px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 16px;
}

p.top-products-title {
  display: inline-block;
  height: 30px;
  color: #110000;
  font-size: 20px;
  font-weight: 600;
}

div.top-products-table-container {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

table.top-products-table {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

table.top-products-table th {
  padding: 10px 0px;
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}

table.top-products-table tr {
  border-bottom: 1px solid #e3e6ee;
}

table.top-products-table tr:last-child {
  border: none !important;
}

table.top-products-table td {
  padding: 16px 0px;
  color: #11100d;
  font-size: 13px;
  font-weight: 600;
}

table.top-products-table td img {
  margin-right: 10px;
  width: 48px;
  height: 48px;
  padding: 5px;
  border: 1px solid #e3e6ee;
  border-radius: 8px;
  object-fit: contain;
}

table.top-products-table td:first-child {
  width: 80px;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

table.top-products-table td:nth-child(2) {
  width: 200px;
}

div.top-products-dropdown {
  position: absolute;
  top: 10px;
  right: 40px;
}

button.top-products-limit-dropdown {
  margin-right: 20px;
  width: 60px;
  padding: 4px 12px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

td.top-products-td-gray {
  color: #999 !important;
}

td.top-products-td-red {
  color: #ff6961 !important;
}
