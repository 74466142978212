div.progress-bar {
  position: relative;
  width: 100%;
  background-color: #d6d6d6;
  overflow: visible;
}

div.progress-bar-thumb {
  height: 100%;
  background-color: #ffc800;
}

div.progress-bar-stop {
  position: absolute;
  background-color: #110000;
  z-index: 10;
  width: 4px;
  height: 8px;
}
