div.chart-line-container {
  position: relative;
  height: 430px;
  margin-top: 10px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 16px;
}

p.chart-line-title {
  display: inline-block;
  height: 30px;
  color: #110000;
  font-size: 20px;
  font-weight: 600;
}

div.chart-line-wrapper {
  position: relative;
  width: 100%;
  height: 350px;
}

div.chart-line-dropdown {
  position: absolute;
  top: 10px;
  right: 40px;
  width: 200px;
  text-align: right;
}
