div.header {
  width: 100%;
  height: 80px !important;
  min-height: 80px !important;
  padding: 5px 0px;
}

div.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 10px auto;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  padding: 0px 30px;
  background-color: #fff;
  box-shadow: 1px 1px #d8d8d8;
}

img.logo {
  width: auto;
  height: 30px;
  cursor: pointer;
}

div.header-right {
  display: flex;
  align-items: flex-end;
  align-items: center;
  height: 45px;
}

div.notification-btn {
  position: relative;
  padding: 3px;
  cursor: pointer;
}

span.notification-dot {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fec802;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

div.alerts-container {
  position: absolute;
  top: 60px;
  width: 300px;
  padding: 0px;
  background-color: #ffffff;
  box-shadow: 4px 2px 12px 0 rgba(202, 202, 202, 0.5);
  border-radius: 12px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
}

div.alerts-container.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.notification-no-alert {
  display: inline-block;
  width: 100%;
  padding: 12px 16px;
  text-align: center;
}

span.notification-clear-all {
  display: inline-block;
  width: 100%;
  padding: 6px 8px;
  text-align: center;
  color: #11100d;
  cursor: pointer;
}

span.notification-clear-all:hover {
  background-color: #f2f2f2;
}

div.notification-list-div {
  position: relative;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
}

span.notification-ingredient-name {
  font-weight: bold;
}

div.header-right-profile {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

img.profile-photo {
  width: 42px;
  height: 42px;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
}

div.header-right-profile-text {
  text-align: left;
  color: #110000;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0;
}

button.switch-account-btn {
  padding: 5px 25px 5px 20px;
  height: 32px;
  border: 1px solid #0d0b09;
  border-radius: 34px;
  background-color: transparent;
  color: #11100d;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
}

button.switch-account-btn:hover {
  border-color: #ffc800;
  background-color: #ffc800;
}

button.switch-account-btn:active {
  border-color: #ffc800;
  filter: brightness(80%);
}

span.sidebar-menu-btn {
  cursor: pointer;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  div.header-right-profile-text {
    display: none;
  }

  div.header-right-profile {
    margin: 0px 5px 0px 5px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  div.header-right-profile-text {
    display: none;
  }

  div.header-right-profile {
    margin: 0px 5px 0px 5px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  div.header-right-profile-text {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  div.header-right-profile {
    margin: 0px 30px 0px 15px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.header-right-profile-text {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  div.header-right-profile {
    margin: 0px 30px 0px 15px;
  }
}
