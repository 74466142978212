div.filter-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

div.filter-part {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

div.filter-search {
  margin-top: 5px;
  height: 41px;
  width: 227px;
  border: 1px solid rgba(137, 148, 182, 0.24);
  border-radius: 12px;
  display: flex;
  align-items: center;
  color: #9fa3ae;
  cursor: pointer;
}

div.filter-search-btn {
  padding: 0px 16px;
}

input.filter-search-box {
  border: none;
}

div.filter-btn-area {
  position: relative;
}

div.filter-btn {
  margin-top: 5px;
  margin-left: 15px;
  height: 41px;
  width: 41px;
  border: 1px solid rgba(137, 148, 182, 0.24);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  cursor: pointer;
}

div.filter-btn:hover {
  background-color: #e7e9ed;
}

div.filter-tag-area {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
}

div.filter-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
  height: 40px;
  color: #000;
  font-family: 'Work Sans';
  font-size: 14px;
  letter-spacing: 0;
  border-radius: 12px;
  background-color: #eeeff1;
}

span.filter-tag-part {
  margin: 0px 10px;
}

div.filter-div {
  position: absolute;
  top: 50px;
  left: 5px;
  padding: 20px 15px;
  z-index: 8000;
  width: 250px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 4px 2px 12px 0 rgba(202, 202, 202, 0.5);
}

p.filter-title {
  font-size: 15px;
  font-weight: bold;
}

span.filter-div-close {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

div.filter-footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

button.filter-add-btn {
  padding: 3px 15px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

div.filter-div .react-datepicker__input-container input {
  font-size: 14px !important;
}

div.filter-div .dropdown {
  width: 100%;
}

button.filter-date-dropdown-btn {
  width: 100%;
  min-height: 47px;
  margin: 8px 0px;
  padding: 12px 17px;
  font-size: 14px;
  color: #11100d;
  border: 1px solid #9299a2;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

span.filter-show-custom-date {
  margin-bottom: 16px;
  padding: 0px 5px;
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}
