p.save-event-bar-title {
  color: #11100d;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

div.save-event-bar-product-div {
  margin-top: 30px;
}
div.save-event-bar-product-list {
  max-height: 200px;
  overflow-y: auto;
}
