div.view-order-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 5px;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #e3e6ee;
}

div.view-order-card-image {
  width: 68px;
  height: 68px;
  border: 1px solid #e3e6ee;
  border-radius: 12px;
  overflow: hidden;
}

div.view-order-card-image img {
  padding: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

div.view-order-card-body {
  position: relative;
  flex: 1;
  margin-left: 12px;
}

span.view-order-card-header-top {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
  color: #11100d;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
}

span.view-order-card-header-title {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
  color: #11100d;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
}

span.view-order-card-header-qr {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

p.view-order-card-title {
  margin: 0px;
  color: #110000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

p.view-order-card-subtitle {
  margin: 0px;
  color: #110000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

span.view-order-card-subtitle-alt {
  color: #555;
  font-size: 14px;
}

span.view-order-card-title-span {
  margin: 0px;
  color: #110000;
  font-size: 14px;
}

span.view-order-span {
  display: inline-block;
  min-width: 150px;
  text-align: left;
  font-weight: normal;
}

span.view-order-discount-total {
  display: flex;
  width: 100%;
  padding: 5px 12px;
  color: #ff6961;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
}

span.view-order-total {
  display: flex;
  width: 100%;
  padding: 5px 12px;
  color: #110000;
  font-size: 18px;
  align-items: center;
  justify-content: space-between;
}

span.view-order-grand-total {
  display: flex;
  width: 100%;
  padding: 5px 12px;
  color: #110000;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
}

div.view-order-card-right {
  width: auto;
}

div.view-order-card-right span {
  display: inline-block;
  width: 100%;
  padding: 5px 0px;
  color: #110000;
  font-size: 18px;
  text-align: center;
}
