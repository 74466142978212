div.prize-list {
  margin: 5px 0px;
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid #e3e6ee;
}

div.prize-list:last-child {
  border: none !important;
}

div.prize-col {
  display: flex;
  align-items: center;
}

span.prize-title {
  margin-left: 12px;
  color: #11100d;
  font-size: 16px;
  font-weight: 600;
}

span.prize-time {
  display: inline-block;
  width: 100%;
  color: #11100d;
  font-size: 20px;
  font-weight: 600;
  text-align: right;
}
