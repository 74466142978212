div.venuecard-container {
  padding: 5px;
}

p.venuecard-field {
  font-size: 16px;
}

p.venuecard-field span {
  font-weight: bold;
}

span.venuecard-error-text {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #c23b22 !important;
  text-align: left;
}
