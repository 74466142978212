div.main-form {
  height: 100vh;
  background-color: #ffc800;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.login-form {
  position: relative;
  margin: auto;
  width: 460px;
  max-width: 460px;
  text-align: center;
}

div.login-form-header {
  margin-top: 30px;
  margin-bottom: 64px;
}

div.login-form-header img {
  width: 280px;
  height: auto;
}

div.rounded-form {
  width: 100%;
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
}

p.login-form-title {
  color: #11100d;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

p.login-form-title-alt {
  color: #222b45;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

button.login-btn {
  color: #11100d;
  font-family: 'Avenir Next';
  font-size: 18px;
  font-weight: bold;
}

form.login-form {
  margin-top: 32px;
}

span.signup-btn {
  color: #ffc800;
  cursor: pointer;
}

span.login-btn {
  color: #ffc800;
  cursor: pointer;
}

span.error-text {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #c23b22 !important;
  text-align: center;
}

div.login-lang-dropdown {
  width: 100%;
}

button.login-lang-dropdown-btn {
  width: 100%;
  font-size: 1rem;
  color: #11100d;
  border: 1px solid #9299a2;
  border-radius: 20px;
  transition: 0.1s ease-out;
  display: flex;
  justify-content: space-between;
}
