div.terminals-card-container {
  position: relative;
  margin-top: 10px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 16px;
}

p.terminals-card-title {
  display: inline-block;
  height: 30px;
  color: #110000;
  font-size: 20px;
  font-weight: 600;
}

div.terminals-card-table-container {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

table.terminals-card-table {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

table.terminals-card-table th {
  padding: 10px 0px;
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}

table.terminals-card-table tr {
  border-bottom: 1px solid #e3e6ee;
}

table.terminals-card-table tr:last-child {
  border: none !important;
}

table.terminals-card-table td {
  padding: 16px 0px;
  color: #11100d;
  font-size: 13px;
  font-weight: 600;
}

table.terminals-card-table td img {
  margin-right: 10px;
  width: 48px;
  height: 48px;
  padding: 5px;
  border: 1px solid #e3e6ee;
  border-radius: 8px;
  object-fit: contain;
}

table.terminals-card-table td:first-child {
  width: 80px;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

table.terminals-card-table td:nth-child(2) {
  width: 200px;
}