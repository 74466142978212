div.modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

div.add-modal {
  position: relative;
  margin: auto;
  padding: 40px 44px 20px 44px;
  background-color: #fff;
  border-radius: 20px;
}

p.add-modal-title {
  position: relative;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  width: 100%;
  color: #11100d;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
}

p.add-modal-subtitle {
  position: relative;
  margin: 0px;
  color: #444;
  font-size: 24px;
  letter-spacing: 0;
  text-align: center;
}
