div.warning-card {
  margin: 12px 0px;
  width: 100%;
  height: auto;
  padding: 12px;
  border-radius: 12px;
  background-color: #fff;
  border-left: 20px solid #ff7900;
  background-color: rgba(255, 121, 0, 0.05);
  color: #fff;
}

div.warning-card h1 {
  margin: 0px 0px 10px 0px;
  padding: 0px;
  color: #444;
  font-size: 1.2em;
  font-weight: 400;
}

div.warning-card p {
  margin: 0px;
  padding: 0px;
  color: #11100d;
  font-size: 1em;
  font-weight: 100;
}

button.warning-card-button {
  margin: 5px 0px;
  padding: 5px 12px;
  background-color: #fff;
  border: none;
  border: 1px solid #11100d;
  border-radius: 16px;
  cursor: pointer;
}

button.warning-card-button:hover {
  background-color: #f2f2f2;
}
