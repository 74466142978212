div.setting-card-col {
  margin-bottom: 16px;
}

div.setting-card-container {
  height: 100% !important;
  padding: 16px 28px;
  border-radius: 12px;
  background-color: #fff;
}

p.setting-card-title {
  color: #110000;
  font-family: 'Work Sans';
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 30px;
}

div.fee-type-div {
  display: flex;
  flex-direction: row;
}

div.fee-type-div label {
  margin-right: 30px;
}

