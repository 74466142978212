div.save-customer-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.save-customer-container div {
  text-align: center;
}

span.save-customer-title {
  color: #110000;
  font-size: 16px;
  font-weight: bold;
}

span.save-customer-label {
  color: #110000;
  font-size: 14px;
}

img.save-customer-img {
  margin-bottom: 12px;
  width: 64px;
  height: 64px;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
}
