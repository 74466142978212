div.refund-status-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

p.refund-status-card-content {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-weight: 400;
}

p.refund-status-card-content.error {
  color: #c23b22;
  font-weight: 600;
}

div.refund-status-card-close {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 18px;
  right: 6px;
  cursor: pointer;
  color: #555;
}

div.refund-status-card-close:hover {
  color: #000;
}
