div.product-category-card {
  margin: 5px 0px;
  padding: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #e3e6ee;
  border-radius: 12px;
  cursor: pointer;
}

div.product-category-card-body {
  width: 100%;
  margin-left: 16px;
}

p.product-category-card-body-title {
  margin: 0px;
  color: #110000;
  font-size: 16px;
  font-weight: 600;
}

div.product-category-remove-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

div.product-category-remove-btn:hover {
  background-color: #e0e0e0;
}
