div.report-summary-col {
  margin-top: 10px;
}

div.report-summary-card {
  margin: 0px;
  padding: 20px 28px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #fff;
}

p.report-summary-card-title {
  margin: 0px;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}

p.report-summary-card-subtitle {
  margin: 0px;
  color: #1ad044;
  font-size: 16px;
  font-weight: 600;
}

p.report-summary-card-subtitle.low {
  color: #e53062;
}

p.report-summary-card-result {
  margin: 16px 0px 0px 0px;
  color: #000000;
  font-size: 28px;
  font-weight: 600;
}

p.report-summary-card-result-large {
  margin: 24px 0px 0px 0px;
  padding: 0px;
  color: #000000;
  font-size: 36px;
  font-weight: 600;
}

p.report-summary-card-subresult {
  margin: 0px;
  padding: 0px;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

p.report-no-content {
  padding: 10px 0px;
  text-align: center;
}

div.report-filter-alt-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 0px 0px 0px;
}

div.report-filter-alt {
  margin: 5px 20px 5px 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

div.report-filter-alt span {
  margin-right: 12px;
}

div.report-filter-alt-checkboxes {
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

div.report-filter-alt-checkboxes label {
  margin-right: 30px;
}
div.report-filter-alt-checkboxes .checkbox-container {
  margin-bottom: 0px !important;
}
