@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../assets/fonts/AvenirNextLTPro-Regular.eot');
  src: url('../assets/fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('../assets/fonts/AvenirNextLTPro-Regular.woff') format('woff'), url('../assets/fonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
    url('../assets/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../assets/fonts/AvenirNextLTPro-It.eot');
  src: url('../assets/fonts/AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextLTPro-It.woff2') format('woff2'),
    url('../assets/fonts/AvenirNextLTPro-It.woff') format('woff'), url('../assets/fonts/AvenirNextLTPro-It.ttf') format('truetype'),
    url('../assets/fonts/AvenirNextLTPro-It.svg#AvenirNextLTPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../assets/fonts/AvenirNextLTPro-Bold.eot');
  src: url('../assets/fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('../assets/fonts/AvenirNextLTPro-Bold.woff') format('woff'), url('../assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype'),
    url('../assets/fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../assets/fonts/AvenirNext-DemiBold.eot');
  src: url('../assets/fonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNext-DemiBold.woff2') format('woff2'),
    url('../assets/fonts/AvenirNext-DemiBold.woff') format('woff'), url('../assets/fonts/AvenirNext-DemiBold.ttf') format('truetype'),
    url('../assets/fonts/AvenirNext-DemiBold.svg#AvenirNextLTPro-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../assets/fonts/worksans-regular-webfont.eot');
  src: url('../assets/fonts/worksans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/worksans-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/worksans-regular-webfont.woff') format('woff'), url('../assets/fonts/worksans-regular-webfont.ttf') format('truetype'),
    url('../assets/fonts/worksans-regular-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* SCROLLBAR */
::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #dbdbdb;
}

body {
  font-family: 'Avenir Next LT Pro', sans-serif;
  margin: 0px !important;
  padding: 0px !important;
}

div#root,
main,
div.main-wrapper {
  height: 100vh;
  margin: 0px !important;
  padding: 0px !important;
}

* {
  box-sizing: border-box;
}

textarea:focus,
input:focus,
button:focus {
  outline: none !important;
}

.margin-auto {
  margin: auto !important;
}

.margin-auto div {
  margin: auto;
}

.overflow-y {
  overflow-y: auto !important;
}

.pointer {
  cursor: pointer !important;
}

.width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.bottom-up {
  bottom: 100% !important;
}

/* TEXTFIELD */
.outlined-textfield {
  position: relative;
  margin: 30px 0px;
}

.outlined-textfield input:disabled {
  background-color: transparent;
  color: #aaa !important;
  border-color: #aaa !important;
  opacity: 1 !important;
  -webkit-text-fill-color: #aaa;
}

.outlined-textfield.sm {
  position: relative;
  margin: 8px 0px;
}

.outlined-textfield.md {
  position: relative;
  margin: 10px 0px;
}

.outlined-textfield.lg {
  position: relative;
  margin: 12px 0px;
}

.outlined-textfield-static-left {
  position: absolute;
  top: 15px;
  left: 10px;
}

.outlined-textfield-error input,
.outlined-textfield-error textarea {
  border-color: #c23b22 !important;
}

.outlined-textfield-error-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 12px;
  color: #c23b22 !important;
  text-align: center;
}

.outlined-textfield.textareafield textarea {
  min-height: 200px;
}

.textareafield .outlined-textfield-error-text {
  position: absolute;
  bottom: 10px !important;
  left: 0px;
  right: 0px;
  font-size: 12px;
  color: #c23b22 !important;
  text-align: center;
}

.outlined-textfield label {
  position: absolute;
  font-size: 1rem;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #9299a2;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.outlined-textfield.textareafield label {
  position: absolute;
  font-size: 1rem;
  left: 10px;
  top: 28px;
  transform: translateY(-50%);
  background-color: transparent;
  color: #9299a2;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.outlined-textfield label.sm {
  font-size: 14px;
}

.outlined-textfield label.md {
  font-size: 15px;
}

.outlined-textfield label.lg {
  font-size: 16px;
}

.outlined-textfield input,
.outlined-textfield textarea {
  width: 100%;
  font-size: 1rem;
  color: #11100d;
  border: 1px solid #9299a2;
  border-radius: 20px;
  transition: 0.1s ease-out;
}

.outlined-textfield textarea {
  resize: none;
}

.outlined-textfield input.sm,
.outlined-textfield textarea.sm {
  padding: 12px 17px;
  font-size: 14px;
}

.outlined-textfield input.md,
.outlined-textfield textarea.md {
  padding: 15px 22px;
  font-size: 15px;
}

.outlined-textfield input.lg,
.outlined-textfield textarea.lg {
  padding: 20px 27px;
  font-size: 16px;
}

.outlined-textfield input:focus,
.outlined-textfield textarea:focus {
  color: #11100d;
  border: 1px solid #11100d;
}

.outlined-textfield input:focus + label {
  color: #11100d;
  top: 0;
  transform: translateY(-50%) scale(0.9);
  background-color: #fff;
}

.outlined-textfield textarea:focus + label {
  color: #11100d;
  top: 0;
  transform: translateY(-50%) scale(0.9);
  background-color: #fff;
}

.outlined-textfield input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  background-color: #fff;
}

.outlined-textfield textarea:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  background-color: #fff;
}

.outlined-textfield div.textfield-left-btn {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  transition: 0.1s ease-out;
  opacity: 0.5;
}

.outlined-textfield input:focus ~ div.textfield-left-btn {
  left: 20px;
  opacity: 1;
}

.outlined-textfield input:not(:placeholder-shown) ~ div.textfield-left-btn {
  left: 20px;
  opacity: 1;
}

.outlined-textfield div.textfield-right-btn {
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
}

.outlined-textfield div.textfield-right-dropdown {
  position: absolute;
  top: 0;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
}

/* DEFAULT BUTTON */
.default-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50px;
  margin: 5px auto;
  padding: 15px 25px 10px 25px;
  color: #11100d;
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 0;
}

.default-button:hover {
  background-color: #e0e0e0;
}

.default-button:active {
  background-color: #dbdbdb;
}

/* DEFAULT BUTTON */
.icon-button {
  border: none;
  border-radius: 50px;
  margin: 5px auto;
  padding: 15px 25px 10px 25px;
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 0;
}

/* LOADING BUTTON */
.loading-button {
  background-color: #ffc800;
  border: none;
  border-radius: 50px;
  margin: 5px 0px;
  padding: 15px 25px 10px 25px;
  color: #11100d;
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 0;
}

.loading-button:hover {
  background-color: #e0b848;
}

.loading-button:active {
  background-color: #d1a835;
}

/* DROPDOWN */
.dropdown-button {
  background-color: transparent;
  color: #000;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-button.primary {
  padding: 16px;
  border-radius: 20px;
  background-color: #fec802;
  color: #000000;
  font-family: 'Avenir Next';
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  min-height: 47px;
}

.dropdown label {
  position: absolute;
  font-size: 0.8rem;
  left: 10px;
  top: 0px;
  background-color: white;
  color: #9299a2;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  pointer-events: none;
}

.table-dropdown-content {
  overflow: hidden;
  background-color: #fff;
  border-radius: 16px;
}

.table-dropdown-content-absolute {
  position: absolute !important;
  z-index: 1000;
}

.table-dropdown-content span {
  display: inline-block;
  width: 100%;
  padding: 12px 20px;
  text-align: left;
  font-family: 'Avenir Next';
  color: #222b45;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  cursor: pointer;
}

.table-dropdown-content span.red {
  color: #ff6961 !important;
}

.table-dropdown-content span:hover {
  background-color: #e0e0e0;
}

.action-btn {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  cursor: pointer;
}

.dropdown-content-wrapper {
  position: absolute;
  margin-left: 0px;
  display: block;
  padding: 0px;
  background-color: #ffffff;
  box-shadow: 4px 2px 12px 0 rgba(202, 202, 202, 0.5);
  width: 100%;
  min-width: 120px;
  border-radius: 10px;
  z-index: 9999;
  max-height: 200px;
}

.dropdown-content-table-wrapper {
  position: absolute;
  margin-left: -44px;
  display: block;
  padding: 0px;
  background-color: #ffffff;
  box-shadow: 4px 2px 12px 0 rgba(202, 202, 202, 0.5);
  min-width: 145px;
  border-radius: 20px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
}

.dropdown-content {
  overflow: hidden;
}

.dropdown-content-wrapper {
  width: 100%;
  max-height: 200px;
  overflow-x: auto;
}

.dropdown-no-max-height {
  max-height: none !important;
}

.dropdown-content-span-no-found {
  position: relative;
  padding: 12px 20px;
  text-align: left;
  display: block;
  font-family: 'Avenir Next';
  color: #222b45;
  font-size: 14px;
  letter-spacing: 0;
}

.dropdown-content span {
  position: relative;
  padding: 12px 20px;
  text-align: left;
  display: block;
  font-family: 'Avenir Next';
  color: #222b45;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  cursor: pointer;
}

.dropdown-content span.red {
  color: #ff6961 !important;
}

.select-dropdown-img {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  object-fit: contain;
}

.dropdown-content span:hover {
  background-color: #e0e0e0;
}

.dropdown-loading {
  position: absolute;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 4px 2px 12px 0 rgba(202, 202, 202, 0.5);
  width: 100%;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  z-index: 9999;
}

/* MAP */
.map {
  position: relative;
  margin: 10px 0px;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 500;
}

.map-error-text {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #c23b22 !important;
  text-align: center;
}

/* CHECKBOX */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: 'Work Sans';
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #110000;
  border-radius: 4px;
}

.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #f2f2f2;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #ffc800;
  border: none;
}

.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkbox-container .checkbox-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #110000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* CONFIRM ALERT */
.confirm-alert {
  z-index: 9999;
}

.react-confirm-alert-body {
  border-radius: 30px;
}

.react-confirm-alert-body > h1 {
  font-size: 36px;
}

.react-confirm-alert-button-group {
  display: flex;
  justify-content: space-between !important;
}

.react-confirm-alert-button-group > button {
  min-width: 130px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: #fec802;
  color: #000000;
  font-family: 'Avenir Next';
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
