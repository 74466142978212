div.ingredient-card-container {
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
}

div.ingredient-card-body {
  position: relative;
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 6px;
}

p.ingredient-card-title {
  margin: 0px;
  color: #110000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

div.ingredient-card-right {
  position: absolute;
  top: 15px;
  right: 35px;
}

div.ingredient-card-item-container {
  margin: 5px 0px;
  width: 100%;
  padding: 6px 12px;
  border: 1.6px solid #d2d2d2;
  border-radius: 16px;
}

div.ingredient-card-item-container.white {
  background-color: #fff;
}

div.ingredient-card-item-container.orange {
  background-color: rgba(255, 165, 0, 0.1);
  border: 1.6px solid rgba(255, 165, 0, 1);
}

div.ingredient-card-item-container.red {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1.6px solid rgba(255, 0, 0, 1);
}

p.ingredient-card-item-title {
  margin: 0px;
  color: #11100d;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

p.ingredient-card-item-subtitle {
  margin: 0px;
  color: #555;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

p.ingredient-card-item-subtitle.black {
  margin: 0px;
  color: #11100d;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
}
