div.default-table-container {
  padding: 16px;
  border-radius: 12px;
  background-color: #fff;
}

div.default-table-wrapper {
  padding: 0px;
  border: 1px solid #e7e9ed;
  border-radius: 12px;
  background-color: transparent;
}

table.default-table {
  display: table;
  width: 100%;
}

table.default-table thead {
  background-color: #f5f6f9;
}

table.default-table thead th:first-child {
  border-top-left-radius: 12px;
}

table.default-table thead th:last-child {
  border-top-right-radius: 12px;
}

table.default-table th {
  color: #110000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding: 12px 16px;
  text-align: left;
}

table.default-table td {
  max-width: 250px !important;
  color: #000000;
  font-family: 'Work Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 3px 16px;
  text-align: left;
  overflow: visible;
}

table.default-table tbody {
  text-align: center;
}

table.default-table tbody tr {
  border-bottom: 1px solid #e7e9ed;
  cursor: pointer;
}

table.default-table tbody tr:hover {
  background-color: #e7e9ed;
}

table.default-table tbody tr:last-child {
  border-bottom: none;
}

table.default-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

table.default-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

div.default-table-loading {
  width: 30px;
  margin: auto;
  padding: 10px;
}

div.default-table-header {
  position: relative;
  margin-bottom: 16px;
  padding: 0px;
  display: flex;
  align-items: center;
}

td.default-table-no-record {
  font-size: 16px !important;
  padding: 10px 0px !important;
  text-align: center !important;
}

div.default-table-footer {
  margin-top: 12px;
  background-color: transparent;
  text-align: right;
}

span.default-table-footer-btn {
  margin: 0px 10px;
  color: #222b45;
  cursor: pointer;
}

button.default-table-footer-dropdown {
  margin-right: 20px;
  width: 60px;
  padding: 4px 12px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

span.default-table-footer-count {
  margin-right: 20px;
  font-size: 13px;
  line-height: 15px;
  color: #110000;
}

span.default-table-value {
  display: inline-block;
  width: 100%;
}

span.default-table-mobile-title {
  display: none;
}

@media (max-width: 1171px) {
  table.default-table,
  table.default-table thead,
  table.default-table tbody,
  table.default-table th,
  table.default-table td,
  table.default-table tr {
    display: block;
  }

  table.default-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  table.default-table tbody tr:hover {
    background-color: transparent;
  }

  table.default-table tr {
    margin: 0 0 1rem 0;
  }

  table.default-table td {
    max-width: 100% !important;
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    text-align: left !important;
    display: flex;
    align-items: center;
    word-wrap: break-word;
  }

  span.default-table-mobile-title {
    display: inline-block;
    width: 40%;
    color: #110000;
    font-size: 14px;
    font-weight: bold;
  }

  span.default-table-value {
    display: inline-block;
    width: 60%;
  }
}
