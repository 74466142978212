div.background {
  height: 100vh;
  background-color: #f5f6f9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

div.body {
  position: relative;
  margin: 25px auto;
  width: 100%;
  max-width: 1440px;
  height: 100vh;
}

div.content {
  position: relative;
  padding: 0px 10px;
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
}

div.content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

div.content-title-label {
  color: #110000;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
}

span.content-title-label-back {
  margin-right: 20px;
  cursor: pointer;
}

span.content-title-label-span span {
  color: #9fa3ae;
}

div.content-title-buttons {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
}

button.content-title-button {
  margin-top: 5px;
  margin-left: 10px;
  border: none;
  border-radius: 34px;
  background-color: #ffc800;
  color: #11100d;
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  padding: 12px 24px;
}

button.content-title-button.default {
  background-color: #e9e9e9 !important;
}

button.content-title-button:hover {
  filter: brightness(90%);
}

button.content-title-button:active {
  filter: brightness(80%);
}

button.content-title-button svg {
  margin-right: 5px;
}

div.content-body {
  padding: 24px 0px;
}

div.content-header {
  position: relative;
  padding: 16px;
  border-radius: 12px;
  background-color: #fff;
}

div.sidebar-overlay {
  position: absolute;
  top: 70px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000;
  opacity: 0.5;
  z-index: 4000;
}

div.sidebar-overlay-hide {
  display: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  div.content {
    padding: 0px 10px;
    width: 100%;
    height: calc(100vh - 100px);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  div.content {
    padding: 0px 10px;
    width: 100%;
    height: calc(100vh - 100px);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  div.content {
    margin-left: 275px;
    width: calc(100% - 285px);
    height: calc(100vh - 100px);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.content {
    margin-left: 275px;
    width: calc(100% - 285px);
    height: calc(100vh - 100px);
  }
}
