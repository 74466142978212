div.prize-spacer {
  width: 100%;
  background-color: transparent;
  margin-bottom: 20px;
}

button.prize-dropdown-btn {
  width: 150px;
  margin: 8px 0px;
  padding: 12px 17px;
  font-size: 14px;
  color: #11100d;
  border: 1px solid #9299a2;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

div.prize-container {
  margin: 16px 0px 0px 0px;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
}

div.prize-container-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.prize-container-option-btn {
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
}

p.prize-container-title {
  margin-bottom: 0px;
  color: #11100d;
  font-size: 20px;
  font-weight: 600;
}

p.prize-container-subtitle {
  color: #9299a2;
  font-size: 13px;
  font-weight: 500;
}

p.prize-no-content {
  padding: 10px 0px;
  text-align: center;
}

div.prize-container-inline {
  display: flex;
  flex-wrap: wrap;
}
