div.profile-card-col {
  margin-bottom: 16px;
}

div.profile-card-container {
  height: 100% !important;
  padding: 16px 28px;
  border-radius: 12px;
  background-color: #fff;
}

p.profile-card-title {
  color: #110000;
  font-family: 'Work Sans';
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
}

input.profile-uploader-file {
  display: none;
}

img.profile-card-photo {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

div.profile-card-lang-dropdown {
  width: 100%;
}

button.profile-card-lang-dropdown-btn {
  width: 100%;
  font-size: 1rem;
  color: #11100d;
  border: 1px solid #9299a2;
  border-radius: 20px;
  transition: 0.1s ease-out;
  display: flex;
  justify-content: space-between;
}
