div.add-option-col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  padding: 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div.add-option-col-2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div.add-option-container {
  position: relative;
  margin: 10px 0px;
  padding: 0px;
}

div.add-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.add-option-header-span {
  padding: 20px 0px 10px 0px;
  font-weight: bold;
}

div.add-option-group {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

button.add-option-add-btn {
  margin: 0px 5px;
  width: 100%;
  padding: 14px 0px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

button.add-option-add-btn:active {
  box-shadow: inset 3px 2px 5px #aaa;
}

div.add-option-list-item {
  width: 100%;
  margin: 5px 0px;
  padding: 15px 22px;
  border: 1px solid #000;
  border-radius: 20px;
  word-wrap: break-word;
  background-color: #fff;
}

div.add-option-list-item span {
  font-weight: bold;
  float: right;
}

div.add-option-col-last {
  position: relative;
}

div.add-option-remove-area {
  position: absolute;
  top: calc(50% - 15px);
  right: -35px;
}

button.add-option-remove-btn {
  margin: 0px 0px 0px 5px;
  padding: 5px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border: 2px solid #ff6961;
  border-radius: 50%;
  font-size: 14px;
  line-height: 13px;
  font-weight: bold;
}

button.add-option-remove-btn:active {
  color: #000;
}

div.add-option-list {
  width: '100%';
}

div.add-option-list-row {
  position: relative;
  margin-top: 5px;
  background-color: #fafafa;
  border-radius: 20px;
}

div.add-option-delete-area {
  position: absolute;
  top: calc(50% - 15px);
  left: -35px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  div.add-option-col {
    width: 100%;
  }
  div.add-option-col-2 {
    width: 100%;
  }
}
