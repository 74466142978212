div.discount-type-div {
  display: flex;
  flex-direction: row;
}

div.discount-type-div label {
  margin-right: 30px;
}

p.discount-type-subtitle {
  padding: 20px 0px 10px 0px;
  font-weight: bold;
}

div.discount-products-area {
  padding: 30px 10px;
  max-height: 200px;
  overflow-y: auto;
}
