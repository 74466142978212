div.add-venue-map {
  position: relative;
  margin: 10px 0px;
  width: 100%;
}

div.add-venue-service-list {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

div.add-venue-service-list label {
  margin: 5px;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 14px !important;
}

p.add-venue-subtitle {
  padding: 20px 0px 10px 0px;
  font-weight: bold;
}

div.add-venue-currency-dropdown {
  min-width: 120px;
  height: 55px;
}

button.add-venue-currency-dropdown-btn {
  width: 100%;
  height: 100%;
  color: #11100d;
  border: 1px solid #9299a2;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

span.add-venue-currency-text {
  display: inline-block;
  padding: 16px 0px;
}
