div.main-form {
  height: 100vh;
  background-color: #ffc800;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.switch-form {
  position: relative;
  width: 490px;
  max-width: 490px;
  text-align: center;
}

div.switch-form-header {
  margin-bottom: 64px;
}

div.switch-form-header img {
  width: 280px;
  height: auto;
}

div.rounded-form {
  width: 100%;
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
}

p.switch-form-title {
  color: #11100d;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

div.switch-form-round {
  position: relative;
  width: 100%;
  border: 2px solid #ececec;
  border-radius: 20px;
}

div.switch-form-no-found {
  padding: 20px 0px;
  text-align: center;
  color: #333;
}

p.switch-form-round-title {
  position: absolute;
  top: -10px;
  left: 20px;
  height: 18px;
  opacity: 0.9;
  color: #9299a2;
  padding: 0px 10px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  background-color: #fff;
}

div.switch-form-splitter {
  position: relative;
}

div.switch-form-splitter hr {
  border: 1px solid #e8e8e8;
}

div.switch-form-splitter p {
  position: absolute;
  top: -10px;
  left: calc(50% - 13px);
  padding: 0px 5px;
  color: #9ca3ab;
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  background-color: #fff;
}

div.switch-form-buttons {
  position: relative;
  margin-top: 25px;
}

div.switch-form-user {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #ececec;
}

div.switch-form-user:last-child {
  border-bottom: none !important;
}

div.switch-form-user-photo {
  display: inline-block;
  width: 60px;
  height: 100%;
}

div.switch-form-user-photo img {
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 50%;
}

div.switch-form-user-body {
  display: inline-block;
  height: 100%;
  text-align: left;
  flex: 1;
}

p.switch-form-user-title {
  margin: 0px;
  color: #110000;
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: 600;
}

p.switch-form-user-subtitle {
  margin: 0px;
  font-family: 'Work Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

p.switch-form-user-remove {
  margin: 0px;
  color: #ff6961;
  font-family: 'Work Sans';
  font-size: 12px;
  cursor: pointer;
}

div.switch-form-user-right {
  display: inline-block;
  height: 100%;
  text-align: right;
}

button.switch-form-user-btn {
  margin: auto;
  padding: 10px 30px;
  border: 1px solid #000000;
  border-radius: 22px;
  background-color: #fff;
  color: #11100d;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
