div.venue-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
}

div.venue-card-image {
  width: 72px;
  height: 72px;
  border: 1px solid #e3e6ee;
  border-radius: 12px;
  overflow: hidden;
}

div.venue-card-image img {
  padding: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

div.venue-card-body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 6px;
}

p.venue-card-title {
  margin: 0px;
  color: #110000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

p.venue-card-subtitle {
  margin: 0px;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

div.venue-card-right {
  display: flex;
  align-items: center;
  height: 100%;
}

div.venue-card-list-container {
  position: relative;
  width: 100%;
}

div.venue-card-list {
  position: relative;
  margin-top: 16px;
}

div.venue-card-list-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 6px 12px;
  border: 1.6px solid #d2d2d2;
  border-radius: 20px;
}

div.venue-card-list-item-body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 6px;
}

p.venue-card-list-item-title {
  margin: 0px;
  color: #11100d;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
