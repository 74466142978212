div.add-member-container {
  position: relative;
  margin: 10px 0px;
  padding: 0px;
}

div.add-member-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.add-member-header-span {
  font-size: 15px;
  font-weight: 600;
}

button.add-member-add-btn {
  padding: 3px 15px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

button.add-member-add-btn:active {
  color: #000;
}

div.add-member-body {
  position: relative;
  width: 100%;
  min-height: 100px;
}

div.add-member-list {
  display: flex;
  justify-content: space-between;
  margin: 6px 0px;
  padding: 12px 25px;
  border: 1.6px solid #d2d2d2;
  border-radius: 20px;
}

div.add-member-footer {
  position: relative;
  width: 100%;
  text-align: center;
}

div.add-member-footer span.error-span {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #c23b22 !important;
  text-align: center;
}

div.remove-member-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

div.remove-member-btn:hover {
  background-color: #e0e0e0;
}

div.member-field {
  cursor: pointer;
}

div.member-field:hover {
  background-color: #d2d2d2;
}

img.add-member-profile {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  object-fit: contain;
}