div.rolecard-container {
  padding: 5px;
}

div.rolecard-list {
  margin-bottom: 24px;
}

div.rolecard-checkbox {
  min-width: 200px;
}

span.rolecard-description {
  text-align: left;
  color: #7f838f;
  font-family: 'Work Sans';
  font-size: 16px;
  letter-spacing: 0;
}