div.prize-user-list {
  margin: 5px 0px;
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid #e3e6ee;
}

div.prize-user-list:last-child {
  border: none !important;
}

div.prize-user-col {
  display: flex;
  align-items: center;
}

span.prize-user-title {
  color: #11100d;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-direction: row;
}

span.prize-user-time {
  display: inline-block;
  width: 100%;
  color: #11100d;
  font-size: 20px;
  font-weight: 600;
  text-align: right;
}

img.prize-user-user-photo {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  object-fit: contain;
}
