p.add-product-subtitle {
  padding: 20px 0px 10px 0px;
  font-weight: bold;
}

div.add-product-no-result {
  width: 100%;
  font-weight: bold;
  color: #111000;
  cursor: pointer;
}

div.add-product-checkbox-container {
  margin-top: 12px;
  padding: 3px;
}

div.add-product-unit-div {
  width: auto;
  display: inline-block;
  margin: 10px 0px;
  padding: 0px 22px;
  color: #9299a2;
  border: 1px solid #9299a2;
  border-radius: 20px;
}

button.add-product-unit-dropdown {
}
