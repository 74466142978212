div.gateway-kv-btn-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.kv-pad-10 {
  padding-left: 10px !important;
}

button.gateway-array-add-btn {
  margin: 0px 5px;
  width: 100%;
  height: 55px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

button.gateway-array-add-btn:active {
  box-shadow: inset 3px 2px 5px #aaa;
}

button.gateway-kv-add-btn {
  margin: 0px 5px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
}

button.gateway-kv-add-btn:active {
  box-shadow: inset 3px 2px 5px #aaa;
}

button.gateway-kv-remove-btn {
  margin: 0px 5px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: transparent;
  border: 1px solid #ff6961;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
}

button.gateway-kv-remove-btn:active {
  box-shadow: inset 3px 2px 5px #aaa;
}

div.gateway-kv-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 0px;
  border-bottom: 1px solid #eee;
}

div.gateway-kv-col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  padding: 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div.gateway-kv-col-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  padding: 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div.gateway-kv-col-last {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15%;
  padding: 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div.col-row-list {
  width: 30%;
  align-items: flex-start !important;
  word-break: break-all;
}

div.col-row-last {
  width: 25%;
  align-items: flex-start !important;
  word-break: break-all;
}

div.gateway-kv-col-row-last {
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div.gateway-kv-type-style {
  width: 100% !important;
}

button.gateway-kv-type-dropdown {
  width: 100%;
  border: 2px solid #000;
  border-radius: 20px;
  padding: 15px 16px;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
}

button.gateway-row-update-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 16px !important;
  text-align: left;
  color: #11100d
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  div.gateway-kv-col {
    width: 100%;
  }
  div.gateway-kv-col-dropdown {
    width: 100%;
  }
  div.gateway-kv-col-last {
    width: 100%;
    text-align: center;
  }
  div.col-row-list {
    width: 100%;
  }
  div.col-row-last {
    width: 100%;
  }
}
