div.save-transfer-checkbox {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

div.save-transfer-checkbox label {
  margin: 0px 12px;
}

p.save-transfer-title {
  padding: 12px 5px 10px 5px;
  font-weight: bold;
}
