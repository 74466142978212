div.employee-detail-container {
  padding: 25px;
  background-color: #fff;
  border-radius: 16px;
}

p.employee-detail-title {
  color: #110000;
  font-family: 'Work Sans';
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
}
