.taginput-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 20px;
  color: black;
  overflow: hidden;
}

.taginput-container input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  padding-left: 14px;
}

.taginput-tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid #ffc800;
  border-radius: 5px;
  background-color: #ffc800;
  white-space: nowrap;
  color: white;
}

.taginput-tag button {
  margin-left: 10px;
  padding: 0px;
  width: 16px;
  height: 16px;
  border: none;
  color: #fff;
  background-color: #e8b600;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}
