div.transfer-page-net-div {
  padding: 0px 0px 10px 0px;
}

div.transfer-summary-col {
  margin-top: 10px;
}

div.transfer-summary-card {
  margin: 0px;
  padding: 10px 14px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #fff;
}

p.transfer-summary-card-title {
  margin: 0px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

p.transfer-summary-card-result-large {
  margin: 6px 0px 0px 0px;
  padding: 0px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

p.transfer-summary-card-result-large.red {
  color: #e53062 !important;
}
