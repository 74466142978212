div.tab-header {
  margin-bottom: 18px;
  width: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

div.tab-header-menu {
  margin: 0px 15px;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  color: #9fa3ae;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  cursor: pointer;
  border-bottom: 4px solid #fff;
}

div.tab-active {
  border-bottom: 4px solid #ffc800;
  color: #000;
}