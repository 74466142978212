div.event-product {
  position: relative;
}

div.event-product-item-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 12px;
  padding: 12px;
  border: 1.6px solid #d2d2d2;
  border-radius: 20px;
}

div.event-product-item-image {
  width: 48px;
  height: 48px;
  border: 1px solid #e3e6ee;
  border-radius: 12px;
  overflow: hidden;
}

div.event-product-item-image img {
  padding: 5px;
  width: 100%;
  height: 100%;
}

div.event-product-item-body {
  position: relative;
  margin-left: 12px;
}

p.event-product-item-title {
  margin: 0px;
  color: #11100d;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

p.event-product-item-subtitle {
  margin: 0px;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

div.event-product-item-right {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  right: 15px;
  height: 60px;
}
