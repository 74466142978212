div.side {
  position: absolute;
  width: 240px;
  left: -240px;
  transition-property: left;
  transition-duration: 0.5s;
  z-index: 5000;
  max-height: 100%;
  overflow-y: auto;
}

div.side-open {
  position: absolute;
  width: 240px;
  left: 10px;
  transition-property: left;
  transition-duration: 0.5s;
  z-index: 5000;
  max-height: 100%;
  overflow-y: auto;
}

div.side-menu {
  position: relative;
  width: 100%;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 16px;
  min-height: 100px;
}

div.side-menu-link {
  position: relative;
  margin: 5px;
  height: 44px;
  padding: 0px 25px;
  cursor: pointer;
  display: flex;
  border-radius: 12px;
  align-items: center;
}

div.side-menu-link:hover {
  background-color: #ffc800;
}

div.side-menu-link:active {
  filter: brightness(80%);
}

div.side-menu-link.active {
  background-color: #ffc800;
}

div.side-menu-link div.side-menu-link-left {
  width: 20px;
}

div.side-menu-link div.side-menu-link-right {
  position: absolute;
  top: 10px;
  right: 25px;
}

div.side-menu-link div.side-menu-link-right img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

div.side-menu-link span.side-menu-link-label {
  margin-left: 15px;
  color: #110000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
}

div.side-menu-alt {
  position: relative;
  width: 100%;
  padding: 0px 0px 20px 0px;
}

div.side-menu-alt button {
  width: 100%;
  border: none;
  border-radius: 34px;
  background-color: #ffc800;
  color: #11100d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 18px 0px;
}

div.side-menu-alt button:hover {
  filter: brightness(90%);
}

div.side-menu-alt button:active {
  filter: brightness(80%);
}

div.side-menu-alt button svg {
  margin-right: 15px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  div.side {
    left: -240px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  div.side {
    left: -240px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  div.side {
    left: 10px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.side {
    left: 10px;
  }
}
