div.opening-hours-area {
  margin: 5px 0px;
}

span.opening-hours-top-title {
  display: inline-block;
  width: 100%;
  text-align: center;
}

div.opening-hours-row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

div.opening-hours-column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
